/**
 * Export Rolodex standard preset.
 */

export const rolodexTransmissionPreset = {
  lights: [
    {
      directional: true,
      intensity: 0.3,
      position: [-3, -7, -3]
    },
    {
      directional: true,
      intensity: 0.3,
      position: [3, -7, -3]
    },

    {
      decay: 5,
      directional: false,
      intensity: 10,
      position: [-1.5, 0.3, 0.4]
    },
    {
      decay: 5,
      directional: false,
      intensity: 10,
      position: [1.5, 0.3, 0.4]
    },
    {
      decay: 5,
      directional: false,
      intensity: 10,
      position: [-1.5, 0.8, 0.95]
    },
    {
      decay: 5,
      directional: false,
      intensity: 10,
      position: [1.5, 0.8, 0.95]
    }
  ],
  tile: {
    color: '#008000',
    metalness: 0.5,
    opacity: 0.96,
    roughness: 0.45
  },
  transmission: {
    distortion: 0,
    distortionScale: 1,
    ior: 1.5,
    iridescence: 0,
    reflectivity: 0.2,
    resolution: 256,
    temporalDistortion: 0,
    thickness: 0.15,
    transmission: 1
  },
  typography: {
    bottomPadding: 0.19,
    descriptionFontSize: 0.09,
    emphasisColor: '#6fe68a',
    leftPadding: 0.22,
    lineHeight: 0.15,
    primaryColor: '#ffffff',
    titleFontSize: 0.145,
    topPadding: 0.16,
    whiteSpace: 0.04
  }
} as const;
