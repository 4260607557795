/**
 * Export `getPercentage` util.
 */

export function getPercentage(value: number, start: number, end: number, clamped: boolean = true): number {
  if (start === end) {
    return NaN;
  }

  const percentage = (value - start) / (end - start);

  return clamped ? Math.min(Math.max(percentage, 0), 1) : percentage;
}
