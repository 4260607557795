/**
 * Module dependencies.
 */

import * as THREE from 'three';
import { DirectionalLightProps, PointLightProps, useLoader } from '@react-three/fiber';
import { Font, FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { MeshTransmissionMaterial } from '@react-three/drei';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { RolodexSectionFragment } from 'src/api/entities/sections/rolodex/types';
import { Tile } from './tile';
import { rolodexStandardPreset } from './presets/standard';
import { rolodexTransmissionPreset } from './presets/transmission';
import { useEffect, useMemo, useState } from 'react';

/**
 * `Props` type.
 */

type Props = {
  cheaperRender: boolean;
  items: RolodexSectionFragment['items'];
  scrollPosition: {
    current: number;
  };
};

/**
 * Export `CanvasContents`.
 */

export const CanvasContents = ({ cheaperRender, items, scrollPosition }: Props) => {
  const [font, setFont] = useState<Font>();

  useEffect(() => {
    const loader = new FontLoader();

    loader.load('/fonts/proxima-nova-semibold.json', font => setFont(font));
  }, []);

  const { material, preset } = useMemo(() => {
    const preset = cheaperRender ? rolodexStandardPreset : rolodexTransmissionPreset;
    const materialProps = { attach: 'material', transparent: true, ...preset.tile };

    return {
      material: cheaperRender ? (
        <meshStandardMaterial {...materialProps} />
      ) : (
        <MeshTransmissionMaterial {...rolodexTransmissionPreset.transmission} {...materialProps} />
      ),
      preset
    };
  }, [cheaperRender]);

  const reversedItems = useMemo(() => [items[0], ...items.slice(1).reverse()], [items]);
  const [tileObj] = useLoader(OBJLoader, '/models/tile-small.obj').children as THREE.Mesh[];

  return (
    <>
      <ambientLight intensity={1} />

      {preset.lights.map(({ directional, ...lightProps }, index) =>
        directional ? (
          <directionalLight key={index} {...(lightProps as DirectionalLightProps)} />
        ) : (
          <pointLight key={index} {...(lightProps as PointLightProps)} />
        )
      )}

      {Array.from({ length: items.length }, (__, index) => (
        <Tile
          description={reversedItems[index].description}
          font={font}
          index={index}
          key={index}
          material={material}
          obj={tileObj.clone()}
          scrollPosition={scrollPosition}
          tileCount={items.length}
          title={reversedItems[index].title}
          typographyProps={preset.typography}
        />
      ))}
    </>
  );
};
