/* eslint-disable id-length */

/**
 * Module dependencies.
 */

import * as THREE from 'three';
import { Center } from '@react-three/drei';
import { getPercentage } from 'src/core/utils/mapping';
import { useFrame } from '@react-three/fiber';
import { useRef } from 'react';

/**
 * `GetGeometryLinesProps` type.
 */

type GetGeometryLinesProps = {
  font: any;
  fontSize: number;
  lineHeight: number;
  string: string;
  whiteSpace: number;
};

/**
 * `GeometryProps` type.
 */

type GeometryProps = {
  geometry: THREE.ShapeGeometry;
  isEmphasized: number;
  xOffset: number;
  yOffset?: number;
};

/**
 * `TypographyProps` type.
 */

type TypographyProps = {
  bottomPadding: number;
  descriptionFontSize: number;
  emphasisColor: string;
  leftPadding: number;
  lineHeight: number;
  primaryColor: string;
  titleFontSize: number;
  topPadding: number;
  whiteSpace: number;
};

/**
 * `TileProps` type.
 */

type TileProps = {
  description: string;
  font: any;
  index: number;
  material: JSX.Element;
  obj: THREE.Mesh;
  scrollPosition: { current: number };
  tileCount: number;
  title: string;
  typographyProps: TypographyProps;
};

/**
 * `splitPoints` type.
 */

const splitPoints = ['<em>', '</em>', '\n'];

/**
 * `getEmphasizedSubstrings` type.
 */

const getEmphasizedSubstrings = (string: string) => {
  const substrings = [];
  let currentIndex = 0;
  let isEmphasized = 0;

  for (let i = 0; i < string.length; i++) {
    for (let j = 0; j < splitPoints.length; j++) {
      if (string.startsWith(splitPoints[j], i)) {
        const str = string.substring(currentIndex, i);

        if (str !== '') {
          substrings.push({ isEmphasized, str });
        }

        if (splitPoints[j] === '\n') {
          substrings.push({ isEmphasized, str: string.substring(i, i + splitPoints[j].length) });
        }

        if (splitPoints[j] === '<em>') {
          isEmphasized++;
        } else if (splitPoints[j] === '</em>') {
          isEmphasized--;
        }

        i += splitPoints[j].length - 1;
        currentIndex = i + 1;
        break;
      }
    }
  }

  if (currentIndex < string.length) {
    substrings.push({
      isEmphasized,
      str: string.substring(currentIndex)
    });
  }

  return substrings;
};

/**
 * `getGeometryLines` component.
 */

const getGeometryLines = ({ font, fontSize, lineHeight, string, whiteSpace }: GetGeometryLinesProps) => {
  const emphasizedSubstrings = getEmphasizedSubstrings(string);
  const geometryLines: { chunks: GeometryProps[] }[] = [{ chunks: [] }];
  let currentGroup = 0;
  let xOffset = 0;

  for (let index = 0; index < emphasizedSubstrings.length; index++) {
    const { isEmphasized, str } = emphasizedSubstrings[index];

    if (str === '\n') {
      xOffset = 0;
      geometryLines.push({ chunks: [] });
      currentGroup++;
      continue;
    }

    const shapes = font.generateShapes(str, fontSize);
    const geometry = new THREE.ShapeGeometry(shapes);

    geometry.computeBoundingBox();

    geometryLines[currentGroup].chunks.push({ geometry, isEmphasized, xOffset });

    if (geometry.boundingBox) {
      xOffset += geometry.boundingBox.max.x - geometry.boundingBox.min.x;
    }

    // (!) Manually account for trailing whitespace (ShapeGeometry trims it)
    if (str[str.length - 1] === ' ') {
      xOffset += whiteSpace;
    }
  }

  const geometries = geometryLines.flatMap((line, index) => {
    const yOffset = (geometryLines.length - 1 - index) * lineHeight;

    line.chunks.forEach(chunk => (chunk.yOffset = yOffset));

    return line.chunks;
  });

  return geometries;
};

/**
 * `Tile` type.
 */

export const Tile = (props: TileProps) => {
  const { description, font, index, material, obj, scrollPosition, tileCount, title, typographyProps } = props;

  let titleMeshes = null;
  let descriptionMeshes = null;

  if (font) {
    const {
      bottomPadding,
      descriptionFontSize,
      emphasisColor,
      leftPadding,
      lineHeight,
      primaryColor,
      titleFontSize,
      topPadding,
      whiteSpace
    } = typographyProps;

    const primaryMat = new THREE.MeshBasicMaterial({ color: primaryColor, side: THREE.DoubleSide });
    const emphasisMat = new THREE.MeshBasicMaterial({ color: emphasisColor, side: THREE.DoubleSide });

    const getMeshes = (string: string, fontSize: number, topAnchor: boolean) => {
      const geometries = getGeometryLines({ font, fontSize, lineHeight, string, whiteSpace });

      return geometries?.map((geometry: GeometryProps, index) => (
        <mesh
          geometry={geometry.geometry}
          key={index}
          material={geometry.isEmphasized ? emphasisMat : primaryMat}
          position={[
            geometry?.xOffset - 0.97 + leftPadding,
            topAnchor ? 0.52 - topPadding : -0.69 + bottomPadding + (geometry.yOffset || 0),
            0.037
          ]}
        />
      ));
    };

    titleMeshes = getMeshes(title, titleFontSize, true);
    descriptionMeshes = getMeshes(description, descriptionFontSize, false);
  }

  const groupRef = useRef<THREE.Group>(null);
  const distance = -Math.PI * 2;
  const pStep = 1 / tileCount;
  const finalPStep = pStep * (tileCount - 1);

  useFrame(() => {
    const loopedScrollPosition = (scrollPosition.current + index / tileCount) % 1;

    const offset =
      distance * 0.55 * getPercentage(loopedScrollPosition, 0, pStep) +
      distance * 0.2 * getPercentage(loopedScrollPosition, pStep, finalPStep) +
      distance * 0.25 * getPercentage(loopedScrollPosition, finalPStep, 1);

    groupRef.current?.rotation.set(offset, 0, 0);
  });

  return (
    <group ref={groupRef}>
      <Center position={[0, 0.03, 0]} top>
        <>
          {titleMeshes}

          {descriptionMeshes}
        </>

        <Center>
          <primitive castShadow object={obj} receiveShadow>
            {material}
          </primitive>
        </Center>
      </Center>
    </group>
  );
};
