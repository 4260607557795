/**
 * Export Rolodex standard preset.
 */

export const rolodexStandardPreset = {
  lights: [
    {
      decay: 3,
      directional: false,
      intensity: 2,
      position: [-1.5, -0.75, 0.5]
    },
    {
      decay: 3,
      directional: false,
      intensity: 2,
      position: [1.5, -0.75, 0.5]
    },
    {
      decay: 6.5,
      directional: false,
      intensity: 4,
      position: [-1.5, 0.3, 0.4]
    },
    {
      decay: 6.5,
      directional: false,
      intensity: 4,
      position: [1.5, 0.3, 0.4]
    },
    {
      decay: 6.5,
      directional: false,
      intensity: 4,
      position: [-1.5, 0.8, 0.95]
    },
    {
      decay: 6.5,
      directional: false,
      intensity: 4,
      position: [1.5, 0.8, 0.95]
    }
  ],
  tile: {
    color: '#008000',
    metalness: 0.8,
    opacity: 0.95,
    roughness: 0.5
  },
  typography: {
    bottomPadding: 0.19,
    descriptionFontSize: 0.09,
    emphasisColor: '#6fe68a',
    leftPadding: 0.22,
    lineHeight: 0.15,
    primaryColor: '#ffffff',
    titleFontSize: 0.145,
    topPadding: 0.16,
    whiteSpace: 0.04
  }
} as const;
